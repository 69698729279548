$black: #1F1B18;
$blue: #7B9FB9;
$yellow: #DEA242;
$orange: #DD6E41;
$red: #FA6D6D;

.t-background-black {
    background-color: $black;
}

.t-background-orange {
    background-color: $orange;
}

.t-background-red {
    background-color: $red;
}

.t-color-blue {
    color: $blue;
}

.t-color-yellow {
    color: $yellow;
}