.c-confetti {
    height: 100%;
}

.c-confetti__item {
    position: absolute;
    animation: confetti 4s ease-in-out -2s 4;
    visibility: hidden;
 }

// todo- refactor - the random function does not work at runtime unfortunately
//  @for $i from 1 through 10 {
//   .c-confetti__item:nth-child(#{$i}) {
//     left: $i * 10%;
//     animation-delay: random(3) - 1 + s;
//     @debug "#{random(6) - 3.5 + s}"
//   }
// }

  .c-confetti__item:nth-child(1) {
    left: 10%; animation-delay: 0;
  }
  .c-confetti__item:nth-child(2) {
    left: 20%; animation-delay: -1.5s;
  }
  .c-confetti__item:nth-child(3) {
    left: 30%; animation-delay: -1s;
  }
  .c-confetti__item:nth-child(4) {
    left: 40%; animation-delay: -1.5s;
  }
  .c-confetti__item:nth-child(5) {
    left: 50%; animation-delay: -2s;
  }
  .c-confetti__item:nth-child(6) {
    left: 60%; animation-delay: -3s;
  }
  .c-confetti__item:nth-child(7) {
    left: 70%; animation-delay: -0.5s;
  }
  .c-confetti__item:nth-child(8) {
    left: 80%; animation-delay: -1s;
  }
  .c-confetti__item:nth-child(9) {
    left: 90%; animation-delay: -1.8s;
  }
  .c-confetti__item:nth-child(10) {
    left: 100%; animation-delay: -1.3s;
  }

  @keyframes confetti {
    0% { transform: rotateZ(15deg) rotateY(0deg) translate(0,0); visibility: visible;}
    25% { transform: rotateZ(5deg) rotateY(360deg) translate(-1vw,20vh); visibility: visible;}
    50% { transform: rotateZ(15deg) rotateY(720deg) translate(2vw,60vh); visibility: visible;}
    75% { transform: rotateZ(5deg) rotateY(1080deg) translate(-2vw,80vh); visibility: visible;}
    100% { transform: rotateZ(15deg) rotateY(1440deg) translate(1vw,110vh); visibility: hidden;}
  }