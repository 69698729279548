$board-max-width: 550px;
$min-cell-width: 20px;
$gap: 5px;
$board-dimension: 9;

.c-board {
    max-width: $board-max-width;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 25px;
}

.c-board__cells {
    display: grid;
    grid-template: repeat($board-dimension, minmax($min-cell-width, 1fr)) / repeat($board-dimension, minmax($min-cell-width, 1fr));
    grid-gap: $gap;
}

.c-board--aspect-ratio {
    display: grid;
    width: 100%;

    &:before {
        content: '';
        padding-bottom: 100%;
    }

    &:before,
    > .c-board__cells--grid-area {
        grid-area: 2/ 1 / 2 / 2;
    }
}