.u-font-sm {
    font-size: 0.75em;
}

.u-font-md {
    font-size: 1em;
}

.u-font-lg {
    font-size: 1.5em;
}

.u-font-xlg {
    font-size: 1.5em;
}

.u-font-2xlg {
    font-size: 2em;
}

.u-font-bold {
    font-weight: 700;
}