.c-board-status-bar {
    display: grid;
    grid-template-columns: 150px 150px;
    grid-template-rows: 75px 75px;
    text-align: center;
    padding-top: 20px;
}

.c-board__title {
    margin-left: 15px;
}