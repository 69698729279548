@font-face {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url("../fonts/quicksand.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
      U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 700;
    font-display: auto;
    src: url(../fonts/quicksand_bold.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
      U+FEFF, U+FFFD;
  }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

html,
body {
    height: 100%;
    font-family: "Quicksand";
    overflow: hidden;
}

#root {
    display: flex;
    align-items: center;
    justify-items: center; /* adjusted */
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
}