.o-layout-flex {
    display: flex;
}

.o-layout-grid {
    display: grid;
}

.o-layout-justify-self-end {
    justify-self: end;
}

.o-layout-justify-between {
    justify-content: space-between;
}

.o-layout-justify-center {
    justify-content: center;
}


.o-layout-align-center {
    align-items: center;
}

.o-layout-flex-shrink-1 {
    flex-shrink: 1;
}